<script setup lang="ts">
import { clear } from 'idb-keyval'
import { useActiveUser } from '~/stores/activeUser'
import { useContentValidation } from '~/stores/contentValidation'
const navOpen = ref(false)

const activeUser = useActiveUser()
const contentValidation = useContentValidation()
const { $auth0, $datadog } = useNuxtApp()
const { user, logout } = $auth0()

const onLogout = () => {
  logout({
    logoutParams: { returnTo: `${window.location.origin}/auth/login` },
  })
}
const onSignOut = async () => {
  try {
    onLogout()
  } catch (error) {
    $datadog.addError(error)
  }

  try {
    localStorage.clear()
    sessionStorage.clear()
    clear()
  } catch (error) {
    $datadog.addError(error)
  }
}

const config = useRuntimeConfig().public
const version = computed(() => `v${config.APP_VERSION}`)

const expand = ref(false)

onMounted(() => {
  contentValidation.init()
})
</script>

<template>
  <AuthPermissionsGuard :permissions="[PERMISSIONS.readTeamContent]">
    <div class="min-h-full">
      <AppNavDrawer v-model="navOpen" class="manager-theme" :version="version">
        <template #logo="{ isMini }">
          <RialticManagerLogo :icon="isMini" />
        </template>
        <template #nav="{ isMini }">
          <ul
            class="flex w-full flex-1 flex-col items-start justify-start space-y-4 overflow-y-auto px-3 text-neutral-300"
          >
            <li class="w-full">
              <AppNavDrawerItem to="/">
                <template #icon>
                  <div class="i-ph-house h-6 h-full w-6" />
                </template>
                Home
              </AppNavDrawerItem>
            </li>

            <li class="w-full">
              <AppNavDrawerItem to="/policy">
                <template #icon>
                  <div class="i-ph-books h-6 h-full w-6" />
                </template>
                Policies
              </AppNavDrawerItem>
            </li>

            <li
              v-if="!isMini"
              class="flex flex w-full items-center space-x-2 pl-2 text-xs tracking-wide"
            >
              <button
                class="btn-icon text-surface"
                aria-label="toggle-expand"
                @click="expand = !expand"
              >
                <div class="i-ph-dots-three-bold h-5 w-5" />
              </button>

              <div>More</div>
            </li>

            <template v-if="expand">
              <div class="h-px w-full bg-neutral-100" />
              <li class="w-full">
                <AppNavDrawerItem to="/source-document">
                  <template #icon>
                    <div class="i-ph-file-search h-6 h-full w-6" />
                  </template>
                  Source Documents
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/provider">
                  <template #icon>
                    <div class="i-ph-address-book h-6 h-full w-6" />
                  </template>
                  Providers
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/engine">
                  <template #icon>
                    <div class="i-ph-cpu h-6 h-full w-6" />
                  </template>
                  Engines
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/topic">
                  <template #icon>
                    <div class="i-ph-tag h-6 h-full w-6" />
                  </template>
                  Topics
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/edit-type">
                  <template #icon>
                    <div class="i-ph-pen h-6 h-full w-6" />
                  </template>
                  Logic Types
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/source-type-level-1">
                  <template #icon>
                    <div class="i-ph-number-square-one h-6 h-full w-6" />
                  </template>
                  Source types level 1
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/source-type-level-2">
                  <template #icon>
                    <div class="i-ph-number-square-two h-6 h-full w-6" />
                  </template>
                  Source types level 2
                </AppNavDrawerItem>
              </li>
              <li class="w-full">
                <AppNavDrawerItem to="/region">
                  <template #icon>
                    <div class="i-ph-globe h-6 h-full w-6" />
                  </template>
                  Region
                </AppNavDrawerItem>
              </li>
            </template>
          </ul>
        </template>

        <AppToolbar class="sticky top-0">
          <template #default>
            <button
              class="h-8 w-8 rounded-full lg:hidden"
              aria-label="toggle menu"
              @click="navOpen = !navOpen"
            >
              <div class="i-ph-list h-6 w-6" />
            </button>
          </template>
          <template #user-menu>
            <NuxtLink
              v-if="contentValidation.errorCount"
              to="/"
              class="bg-error-300 border-error text-error-700 flex items-center space-x-4 rounded-full border-2 p-2 py-1"
            >
              <div
                v-if="contentValidation.initializing"
                class="i-ph-circle-notch-bold mr-2 block h-6 w-6 animate-spin"
              />
              <div v-else class="i-ph-warning-bold mr-2 block h-6 w-6" />

              {{ contentValidation.errorCount }} errors
            </NuxtLink>

            <AppUserMenu :profile-img-url="user?.picture" logo-url="">
              <ul class="flex flex-col p-2">
                <li v-if="user" class="p-1 px-2 pb-2">
                  <h3
                    v-if="activeUser.fullName"
                    class="subtitle-2 text-neutral-900"
                  >
                    {{ activeUser.fullName }}
                  </h3>
                  <p class="body-2 text-neutral-800">{{ activeUser.email }}</p>
                </li>
                <li>
                  <button class="user-menu-link" @click="onSignOut()">
                    Log out
                  </button>
                </li>
              </ul>
            </AppUserMenu>
          </template>
        </AppToolbar>
        <div class="bg-surface flex-1">
          <slot v-if="activeUser.user" />
          <div v-else class="pt-10">
            <UiLoading class="m-auto h-20 w-20" />
          </div>
        </div>
      </AppNavDrawer>
    </div>
    <template #fallback>
      <Teleport to="body">
        <div class="z-1000 fixed inset-0">
          <AuthPermissionDeniedScreen
            title="That didn't quite work..."
            @logout="onSignOut()"
          />
        </div>
      </Teleport>
    </template>
  </AuthPermissionsGuard>
</template>

<style scoped>
.manager-theme {
  --rialtic-color-primary: #0270a2;
  --rialtic-color-primary-light: #4f94b4;
}

.user-menu-link {
  @apply inline-flex w-full rounded-lg px-4 py-2 text-left text-sm font-light hover:bg-neutral-100 hover:text-neutral-800;
}
</style>
